<template>
  <div class="introduction">
    <div class="content">
      <div class="product_tab">
        <ul class="w-flex w-row-wrap w-row-center">
          <li v-for="(item, index) in globData.group101" :key="index" :class="index === tabActive?'active':''" @click="tabChange(index)">

            <a @click="tabChange(index)">
              <i class="icon_border" :class="index === tabActive?'icon_border_active':''"></i>
              {{item.name}}
            </a>
          </li>
        </ul>
      </div>
      <b-container>
        <b-row class="w-m-t-30">
          <b-col class="txt-333 txt-s16">

            <div v-for="(item, index) in globData.group101" :key="index">
              <div v-if="index === tabActive" v-html="item.value"></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
  data() {
    return {
      tabActive: 'Products',
      globData : false
    }
  },
  methods: {
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
    tabChange(index) {
      this.tabActive = index
    }
  },
  mounted() {
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  }
}
</script>
<style scoped lang="scss">
.product_tab {
  border: 4px solid #42dcd6;
  margin-top: 30px;
  margin-bottom: 30px;
ul {
li {
// width: 25%;
  color: #B3B4B4;
  font-size: 16px;
  margin-right: 80px;
  cursor: pointer;
  position: relative;
&:last-child {
   margin-right: 0;
 }
.icon_border {
  display: inline-block;
  border: 2px solid #35c9da;
  background: #FFFFFF;
  width: 13px;
  height: 13px;
  border-radius: 100%;
}
.icon_border_active {
  background: #35c9da;
}
&:nth-child(1){
&:before {
   content: '';
   width: 4px;
   height: 130%;
   position: absolute;
   top: -12px;
   right: -37px;
   background: #35c9da;
   transform: rotate(142deg);
 }
}
}
.active {
  color: #35C9DA;
}
a {
  display: inline-block;
  padding-top: 32px;
  padding-bottom: 32px;
}
}
}

.line_li_gr {
span {
  display: inline-block;
  width: 89px;
  height: 6px;
  background: linear-gradient(270deg, #1A97E0, #42DBD6);
}

}
</style>
