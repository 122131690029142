import axios from 'axios'
import { JSEncrypt } from 'jsencrypt'
import {
  baseUrl
} from './api.js'

const RsaKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC2qg5MpM8HCh8U/tsxi9A8fWAP3BbvfwsISPybdGgGM8O10w/zwAnJz8WLNB5qSGU5MzoD6LtgCq1bi/fEfeGxYl/ORrKelha6v3/Qiq7djPD0h2GbUixPR8Y0pkUjdECdRtzv8tjQl6NsYilY0dsF9cfW5VpyCQz1IrwB50k6oQIDAQAB";

export default {

  // 新闻
  noticeList(params) {
    return axios.get(baseUrl + 'News.GetRecommend', {
      params: params
    })
  },
  geEncryptStr(str) {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(RsaKey);
    return encrypt.encrypt(str);
  },
  request(url = "", data = {}, callback = () => {}, _this, async=true) {
    if (url.substr(0, 4) != 'http') {
      url = baseUrl + url;
    }
    let Timestamp = (Date.parse(new Date()) / 1000).toString();
    let Key = this.geEncryptStr(Timestamp);

    data.Key = Key;
    data.Timestamp = Timestamp;

    try {
      axios({
        method: 'get',
        url: url,
        params: data,
        async : async
      }).then(data => {
        callback(data.data);
      })
    } catch (error) {
      console.error(error);
    }
  }
}
