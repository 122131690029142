<template>
  <div class="w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <b-row class="title_box">
          <b-row style="max-width: 1200px;width: 100%">
            <b-col cols="6" v-for="(item, index) in secondaryMenuList" :key="index" class="newsMenu w-txt-center"
                   :class="menuActive === item.type?'menuActive':''" @click="tabChange(item.type)">
              {{item.name}}
            </b-col>
          </b-row>
          </b-row>
        <div class="bg2">
        <div class="max-width">
          <div class="w-m-t-30 w-p-t-20">
            <b-container v-if="menuActive==1">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group4.dzzjg.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==2">
              <div class="w-m-t-30 w-p-t-20 max-width">
                <b-container class="newList">
                  <b-row class="w-m-t-30 newListContent" v-for="(item, index) in newsList" :key="index">
                    <b-col sm="12" md="12" lg="4">

                      <router-link :to="{path: '/chinese/news/newsDetail', query:{id: item.id}}">
                        <b-img-lazy :src="item.images" alt="Image 1" class="w-w-full"></b-img-lazy>
                      </router-link>
                    </b-col>
                    <b-col class="w-relative">
                      <h2><router-link :to="{path: '/chinese/news/newsDetail', query:{id: item.id}}">{{item.title}}</router-link></h2>
                      <p>{{item.describe}}</p>
                      <div class="w-flex w-row-between btnDetail">
                        <span>{{item.created_at}}</span>
                        <router-link :to="{path: '/chinese/news/newsDetail', query:{id: item.id}}" class="seeDetail">查看详情</router-link>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>

                <div class="w-flex w-row-center">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="pageSize"
                      aria-controls="my-table"
                      @input="pageChange(currentPage)"
                  ></b-pagination>
                </div>
              </div>
            </b-container>
          </div>
        </div>
        </div></div>
    </div>
  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
  data() {
    return {
      secondaryMenuList:[
        {
          name: "党组织机构",
          path: "/chinese/pages/gardenPlot",
          type: 1
        },
        {
          name: "党建活动",
          path: "/chinese/pages/gardenPlot",
          type: 2
        }
      ],
      newsList: [],
      total: 0, // 总条数
      pageSize: 10, // 条数大小
      currentPage: 1, // 当前页
      menuActive: 1,
      bannerList: [],
      title: '党的建设',
      globData : false
    }
  },
  created() {

  },
  methods: {
    // 获取新闻列表
    getNewsList (PageIndex, type) {
      http.request('News.List', {
        Type: type,
        PageIndex: PageIndex,
        PageSize: this.pageSize,
        KeyWord: this.$route.query.header || ''
      }, (res) => {
        if (res.flag == 200) {
          for (let item of res.data.list) {
            item.created_at = timestampToTime(item.created_at)
          }
          this.newsList = res.data.list
          this.total = res.data.total
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
    tabChange (index) {
      this.menuActive = this.newsType = index
      window.location.href = '/#'+this.$route.name+'?type=' + this.newsType
    },
    getNewsType () {
      this.menuActive = parseInt(this.$route.query.type) || this.newsType || 1
    },
    urlChange () {
      this.getNewsType()
    }
  },
  mounted() {
    this.menuActive = parseInt(this.$route.query.type) || 1
    this.getNewsList(this.currentPage, 4)
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  },
  watch: {
    '$route': 'urlChange'
  }
}
</script>
<style scoped lang="scss">
.newList {
  color: #333333;
  width: 1140px;
.newListContent {
  border-bottom: 1px solid #F3F3F3;
  padding-bottom: 42px;
  margin-bottom: 42px;
h2 {
  font-size: 18px;
  font-weight: bold;
  color: #4D93D8;
  margin-bottom: 42px;
}
p {
  font-size: 14px;
  line-height: 1.4;
}
.btnDetail {
// position: absolute;
  bottom: 10px;
  width: 100%;
  margin-top: 20px;
.seeDetail {
  display: inline-block;
  font-size: 16px;
  border: 1px solid #E1E1E1;
  padding: 10px 30px;
  border-radius: 20px;
  cursor: pointer;
}
}
}

}
</style>
