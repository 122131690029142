import axios from 'axios'
import {
  baseUrl
} from '@/http/api.js'
/**
 * 格式化时间（年，月，日）
 * @param {*} key
 */
export function timestampToTime(timestamp) {
  var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';

  return Y + M + D;
}

export function getGlobSettingTop() {

  var key = 'globSetting'
  var _this = this
  requestTop('Home.GetGlobSetting', {}, (res) => {
    if (res.flag == 200) {
      localStorage.setItem(key, JSON.stringify(res.data))

      return res.data;
    }
  })
}
export async function getDicsList(callback, sync=false) {
  var result = {};
  var key = 'globSetting';
  var store = localStorage.getItem(key)
  if(localStorage.getItem(key) && !sync) return callback(JSON.parse(store));
  await axios.get(baseUrl + 'Home.GetGlobSetting', {
    params: {
      Key: 123,
      Timestamp: 234,
    }
  }).then((response) => {
    result = response.data.data;

    localStorage.setItem(key, JSON.stringify(result));
  });
  return callback(result)
}

function requestTop(url = '', data = {}, callback = () => {}, _this) {
  if (url.substr(0, 4) != 'http') {
    url = baseUrl + url
  }
  let Timestamp = (Date.parse(new Date()) / 1000).toString()

  data.Key = '123456'
  data.Timestamp = Timestamp

  try {
    axios({
      async: false,
      method: 'get',
      url: url,
      params: data
    }).then(data => {
      callback(data.data)
    })
  } catch (error) {
    console.error(error)
  }
}


export function dispatchEventStroage() {
  const signSetItem = localStorage.setItem
  localStorage.setItem = function(key, val) {
    let setEvent = new Event('setItemEvent')
    setEvent.key = key
    setEvent.newValue = val
    window.dispatchEvent(setEvent)
    signSetItem.apply(this, arguments)
  }
}
export function getStorge() {
  var key = 'globSetting';
  var stroge = localStorage.getItem(key);
  if (!stroge) {
    setTimeout(function() {
      return getStorge();
    }, 100)
  } else {
    return JSON.parse(stroge);
  }
}
