<template>
  <div class="w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <b-row class="title_box">
          <b-row style="max-width: 1200px;width: 100%">
            <b-col cols="2" v-for="(item, index) in secondaryMenuList" :key="index" class="newsMenu w-txt-center"
                   :class="menuActive === item.type?'menuActive':''" @click="tabChange(item.type)">
              {{item.name}}
            </b-col>
          </b-row>
          </b-row>
        <div class="bg2">
        <div class="max-width">
          <div class="w-m-t-30 w-p-t-20">
            <b-container v-if="menuActive==1">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group3.dszll.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==2">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group3.ppzl.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==3">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group3.qyll.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==4">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group3.qzwhy.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==5">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group3.qdzg.value"></div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        </div></div>
    </div>
  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils'
export default {
  data() {
    return {
      secondaryMenuList:[
        {
          name: "党委书记理念",
          path: "/chinese/pages/corporateCulture",
          type: 1
        },
        {
          name: "发展战略",
          path: "/chinese/pages/corporateCulture",
          type: 2
        },
        {
          name: "企业精神",
          path: "/chinese/pages/corporateCulture",
          type: 3
        },
        {
          name: "孔子文化园",
          path: "/chinese/pages/corporateCulture",
          type: 4
        },
        {
          name: "东强之歌",
          path: "/chinese/pages/corporateCulture",
          type: 5
        }
      ],
      menuActive: 1,
      bannerList: [],
      title: '企业文化',
      globData : false
    }
  },
  created() {

  },
  methods: {
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
    tabChange (index) {
      this.menuActive = this.newsType = index
      window.location.href = '/#'+this.$route.name+'?type=' + this.newsType
    },
    getNewsType () {
      this.menuActive = parseInt(this.$route.query.type) || this.newsType || 1
    },
    urlChange () {
      this.getNewsType()
    }
  },
  mounted() {
    this.menuActive = parseInt(this.$route.query.type) || 1
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  },
  watch: {
    '$route': 'urlChange'
  }
}
</script>
