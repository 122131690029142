<template>
  <div class="w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
          <b-row class="title_box">
            <b-row style="max-width: 1200px;width: 100%">
            <b-col cols="3" v-for="(item, index) in secondaryMenuList" :key="index" class="newsMenu w-txt-center"
                   :class="menuActive === item.type?'menuActive':''" @click="tabChange(item.type)">
              {{item.name}}
            </b-col>
            </b-row>
          </b-row>
        <div class="bg2">
        <div class="max-width">
          <div class="w-m-t-30 w-p-t-20">
            <b-container v-if="menuActive==2">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group1.qygk.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==3">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group1.fzlc.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==1">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group1.ldzc.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==4">
              <b-row class="w-m-t-30">
                <b-col class="txt-333 txt-s16">
                  <div v-html="globData.group1.cqxm.value"></div>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="menuActive==5">
              <b-row class="sm_left">
                <b-col v-for="(item, index) in globData.group2" :key="index" sm="12" md="4" lg="4">
                  <div class="rowIntroduce w-m-b-15">
                    <div class="newsImg">
                      <img :src="item.value" alt="Image 1" class="w-w-full hoverImg" @click="openBig(item.value)">
                      <div class=" txt-333">
                        <h2 class="w-p-l-5 w-m-t-20 txt-s16 w-txt-center">
                          {{item.name}}
                        </h2>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        </div></div>
    </div>
  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
  data() {
    return {
      secondaryMenuList:[
        {
          name: "领导致辞",
          type: 1
        },
        {
          name: "企业概况",
          type: 2
        },
        {
          name: "发展历程",
          type: 3
        },
        // {
        //   name: "厂区新貌",
        //   type: 4
        // },
        {
          name: "荣誉资质",
          type: 5
        }
      ],
      menuActive: 1,
      bannerList: [],
      title: '企业概况',
      globData : false
    }
  },
  created() {

  },
  methods: {
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
    tabChange (index) {
      this.menuActive = this.newsType = index
      window.location.href = '/#'+this.$route.name+'?type=' + this.newsType
    },
    getNewsType () {
      this.menuActive = parseInt(this.$route.query.type) || this.newsType || 1
    },
    urlChange () {
      this.getNewsType()
    },
    openBig(src) {
      window.open(src);
    }
  },
  mounted() {
    this.menuActive = parseInt(this.$route.query.type) || 1
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  },
  watch: {
    '$route': 'urlChange'
  }
}
</script>
<style scoped lang="scss">
.speechContent {
  padding: 100px 30px;
  background: #4D93D8;
  position: relative;
  &:after {
     content: '';
     position: absolute;
     top: 50px;
     width: 80%;
     left: 10%;
     border-top: 1px solid #FFFFFF;
   }
  .contentMain {
    background: #FFFFFF;
    border: 2px solid #aabacb;
    padding: 70px 88px 80px 40px;
    p {
      font-size: 15px;
      font-family: cursive;
      text-decoration: underline;
      color: #333333;
      font-weight: bold;
    }
    h2 {

      font-size: 36px;
      font-family: cursive;
      font-weight: 400;
      color: #0B0B0B;
    }
  }
}
.rowIntroduce {
  background: #FFFFFF;
  border: 1px solid #E5E8EB;
  padding: 8px;
  position: relative;
  .newsImg {
    padding: 0 15px;
  }
}
</style>
