<template>
  <div class="contactUs">
    <div class="content">
      <b-container class="bv-example-row w-p-t-30">
        <b-row class="contactUs_content">
          <b-col sm="8" md="6" lg="6" class="w-m-t-20">
            <div class="address_right">
              <div v-html="globData.group105.Contact.value"></div>
            </div>
          </b-col>
        </b-row>

      </b-container>
    </div>
  </div>
</template>
<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
  data() {
    return {
      globData : false
    }
  },
  methods: {
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
  },
  mounted() {
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  }
}
</script>
<style scoped lang="scss">
  .line_li_gr {
    span {
      display: inline-block;
      width: 89px;
      height: 6px;
      background: linear-gradient(270deg, #1A97E0, #42DBD6);
    }

  }

  .contactUs_content {
    padding: 10px;
    padding-bottom: 30px;
    border: 3px solid #42DCD6;
    margin-bottom: 50px;
  }

  .address_right {
    h1 {
      font-size: 18px;
      font-weight: 600;

      span {
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #999999;
    }
  }
</style>
