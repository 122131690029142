<template>
  <div class="list w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <b-row class="title_box" v-if="hideNewsMenuList !== 1">
          <b-row style="max-width: 1200px;width: 100%">
          <b-col cols="4" v-for="(item, index) in newsMenuList" :key="index" class="newsMenu w-txt-center"
                 :class="menuActive === item.type?'menuActive':''" @click="tabChange(item.type)">
            {{item.title}}
          </b-col>
          </b-row>
        </b-row>
        <div class="w-m-t-30 w-p-t-20 max-width">
          <b-container class="newList">
            <b-row class="w-m-t-30 newListContent" v-for="(item, index) in newsList" :key="index">
              <b-col sm="12" md="12" lg="4">

                <router-link :to="{path: '/chinese/news/newsDetail', query:{id: item.id}}">
                  <b-img-lazy :src="item.images" alt="Image 1" class="w-w-full"></b-img-lazy>
                </router-link>
              </b-col>
              <b-col class="w-relative">
                <h2><router-link :to="{path: '/chinese/news/newsDetail', query:{id: item.id}}">{{item.title}}</router-link></h2>
                <p>{{item.describe}}</p>
                <div class="w-flex w-row-between btnDetail">
                  <span>{{item.created_at}}</span>
                  <router-link :to="{path: '/chinese/news/newsDetail', query:{id: item.id}}" class="seeDetail">查看详情</router-link>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <div class="w-flex w-row-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              aria-controls="my-table"
              @input="pageChange(currentPage)"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
export default {
  data () {
    return {
      newsMenuList: [
        {
          type: 1,
          title: '最新动态'
        },
        {
          type: 2,
          title: '媒体关注'
        },
        {
          type: 3,
          title: '品牌故事'
        }
      ],
      menuActive: 1,
      newsList: [],
      total: 0, // 总条数
      pageSize: 10, // 条数大小
      currentPage: 1, // 当前页
      newsType: 1,
      hideNewsMenuList: 0
    }
  },
  watch: {
    '$route': 'getNewsType'
  },
  created () {

  },
  methods: {
    // tab切换
    tabChange (index) {
      this.menuActive = this.newsType = index
      window.location.href = '/#/chinese/news/newsCenter?type=' + this.newsType
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.getNewsList(this.currentPage, this.newsType)
      }
      // this.getNewsList(this.currentPage, this.newsType)
    },
    // 获取新闻列表
    getNewsList (PageIndex, type) {
      http.request('News.List', {
        Type: type,
        PageIndex: PageIndex,
        PageSize: this.pageSize,
        KeyWord: this.$route.query.header || ''
      }, (res) => {
        if (res.flag == 200) {
          for (let item of res.data.list) {
            item.created_at = timestampToTime(item.created_at)
          }
          this.newsList = res.data.list
          this.total = res.data.total
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    pageChange (currentPage) {
      this.getNewsList(currentPage, this.newsType)
    },
    getNewsType () {
      this.menuActive = parseInt(this.$route.query.type) || this.newsType || 1
      this.hideNewsMenuList = parseInt(this.$route.query.show)
    },
    urlChange () {
      this.getNewsType()
      this.getNewsList(1, parseInt(this.$route.query.type) || this.newsType || 1)
    }
  },
  activated () {
    this.hideNewsMenuList = parseInt(this.$route.query.show)
  },
  mounted () {
    this.getNewsList(this.currentPage, this.$route.query.type)
    this.menuActive = parseInt(this.$route.query.type) || 1
    console.log(this.menuActive)
  },
}
</script>
<style scoped lang="scss">
.newList {
  color: #333333;
  width: 1140px;
  .newListContent {
    border-bottom: 1px solid #F3F3F3;
    padding-bottom: 42px;
    margin-bottom: 42px;
    h2 {
      font-size: 18px;
      font-weight: bold;
      color: #4D93D8;
      margin-bottom: 42px;
    }
    p {
      font-size: 14px;
      line-height: 1.4;
    }
    .btnDetail {
      // position: absolute;
      bottom: 10px;
      width: 100%;
      margin-top: 20px;
      .seeDetail {
        display: inline-block;
        font-size: 16px;
        border: 1px solid #E1E1E1;
        padding: 10px 30px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }

}
</style>
