<template>
  <div id="app">
      <headerNav v-if="isRouterAlive"/>
      <keep-alive exclude="detail">
        <router-view />
      </keep-alive>
      <keep-alive exclude="detail">
        <footerPage />
      </keep-alive>
  </div>
</template>

<script>
import axios from 'axios'
import {
  baseUrl
} from '@/http/api.js'

export default {
  name: 'App',

  components: {
    headerNav: () => import('@/components/header'),
    footerPage: () => import('@/components/footer')
  },
  provide () {
    return {
      reload: this.reload// 调用reload方法
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      // 在修改数据之后使用 $nextTick，则可以在回调中获取更新后的 DOM
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
  },
  created () {

  },
  mounted () {
    // setTimeout(() => {
    //   this.showLoading = false
    // }, 4000)
  }
}
</script>

<style>
  #app {
    font-size: 16px;
  }


  #nprogress .bar {
    background: #2085c5 !important;
    height: 10px !important;
  }
</style>
