<template>
	<div class="home">
    <div class="content w-w-full">
      <div class="max-width">
        <div class="w-m-t-30 w-p-t-20">
          <b-container>
            <b-row class="w-m-t-30">
              <b-col class="txt-333 txt-s16">
                <div v-html="globData.group109.En_Home.value"></div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
		</div>
	</div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
	data() {
		return {
      globData : false
		}
	},
  methods: {
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
  },
  mounted() {
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  }
}
</script>
<style scoped lang="scss">
$yellow: #FFAC08;
$blue: #0f649b;
.moreHover {
	color: $blue;
	border: 1px solid #FFFFFF;
	background: #FFFFFF;
	cursor: pointer;
}
.moreBntStyle {
	background: #74A5DC;
	border-radius: 12px;
	padding: 9px 36px;
	color: #FFFFFF;
}
.home {
	.content {
    .hover_top {
      &:before {
        transform: rotate(180deg);
        top: 0;
      }
      .hover_title {
        top: 20px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        margin: auto;
        width:0;
        height:0;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 10px solid #FFFFFF;
      }
    }

		.service {
			background: #FFFFFF;
			.Chemical_medicine {
				background: #A15BBD;
				height: 274px;
        text-align: center;
        line-height: 274px;
			}
			.Chemical_medicine_tip {
				background: linear-gradient(0deg, #E7FAFD, #FFFFFF);
				opacity: 0.9;
				height: 274px;
				.border_z {
					display: inline-block;
					width: 4px;
					height: 4px;
					border: 1px solid #74A5DC;
					margin-right: 6px;
				}
				.more {
					bottom: 15px;
					right: 15px;
          cursor: pointer;
					@extend .moreBntStyle;
				}
			}
			.Health_products {
				background: #F4D785;
			}
			.traditional_Chinese_medicine {
				background: #BD5B82;
			}
			.medical_instruments {
				background: #5BB5BD;
			}
		}
		.title {
			h2 {
				color: #4D93D8;
			}
		}
		.map {
			background: linear-gradient(180deg, #0385EC, #44DDD5);
      padding-top: 100px;
      padding-bottom: 100px;
		}
	}
}
</style>
