、
<template>
  <div class="footer">
    <div class="footer_box" :class="bgc?'bgc_blue':''" v-if="EN">
      <div class="w-flex w-row-center">
        <div class="content container">
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <div class="w-txt-center w-line-h-1_4" style="font-size: 14px; margin: 15px; line-height: 20px;">
                  公司地址：江苏省东台市头灶镇政府街18号　邮政编码：224247<br>
                  　客服邮箱：dq@dongqiang.net　客服电话：0515- 85481123<br>
                  　 www.dongqiang.com © 江苏东强股份有限公司	<br>
                  <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备05076944号</a>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <div class="footer_EN" v-else>
      <div class="x-w container">
        <b-row>
          <b-col sm="12" md="12" lg="6">
            <h2>More about DQ cables</h2>
            <div class="address">
              <p>{{ ENFooter.title.toUpperCase() }}</p>
              <p><span class="txt-bold">ADDRESS:</span> <font>{{ ENFooter.address.toUpperCase() }}</font></p>
              <p><span class="txt-bold">ZIP code:</span> <font>{{ ENFooter.zip_code.toUpperCase() }}</font></p>
              <p style="position: relative;height: 60px"><span class="txt-bold">Telephone:</span><span style="display: block;position: absolute;left: 90px;top: 6px;line-height: 25px" v-html="ENFooter.Telephone.toUpperCase()"></span></p>
              <p style="position: relative;height: 60px"><span class="txt-bold">Fax:</span> <span style="display: block;position: absolute;left: 90px;top: 6px;line-height: 25px" v-html="ENFooter.Fax.toUpperCase()"></span></p>
              <p style="position: relative;height: 90px"><span class="txt-bold">web:</span> <span style="display: block;position: absolute;left: 90px;top: 6px;line-height: 25px" v-html="ENFooter.web.toUpperCase()"></span></p>
              <p style="position: relative;height: 60px"><span class="txt-bold">Email:</span> <span style="display: block;position: absolute;left: 90px;top: 6px;line-height: 25px" v-html="ENFooter.Email.toUpperCase()"></span></p>
            </div>
          </b-col>
          <b-col class="w-txt-right" sm="12" md="12" lg="6">
            <h1>{{ ENFooter.contact.toUpperCase() }}</h1>
            <h1>{{ ENFooter.information.toUpperCase() }}</h1>
          </b-col>
        </b-row>
        <div class="border_bottom"></div>
        <div class="copyright">
          <p v-html="ENFooter.copyright.toUpperCase()"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getDicsList } from '@/utils/index.js'
export default {
  data () {
    return {
      footerMenuList: [
        {
          title: '关于我们',
          path: ''
        },
        {
          title: '版权声明',
          path: ''
        },
        {
          title: '友情链接',
          path: ''
        },
        {
          title: '网站地图',
          path: ''
        },
        {
          title: '联系我们',
          path: ''
        },
        {
          title: '新华网',
          path: '',
          last: true
        }
      ],
      bgc: false,
      EN: false,
      ENFooter: {
        title: '<strong>DQ cables</strong> is a registered trade mark of Jiangsu DongQiang Cables Co., Ltd.',
        address: 'No. 18 Zhengfu Road, Dongtai, Jiangsu, China',
        zip_code: '224247',
        Telephone: '4000515268 (toll free )<br>\t+86051585481468(bilingual)',
        Fax: '+86051585480288<br>+86051585482999',
        web: 'www.DQ-cables.com\n' +
            '<br>' +
            'www.dongqiang.com\n' +
            '<br>' +
            'www.cndongqiang.com',
        Email: 'sales@DQ-cables.com<br>dq@dongqiang.net',
        contact: 'Contact ',
        information: 'information',
        copyright: 'Copyright 2011 Jiangsu Dongqiang Co., Ltd. All rights reserved. <br>Protected by national and international copyright laws.'
      },
        globData : false
    }
  },
  created () {
    this.urlChange();
  },
  methods: {
    urlChange () {
      this.EN = this.$route.name.substr(1, 7) != 'chinese' ? false : true;

      if (this.$route.name !== '/home/home') {
        this.bgc = true
      } else {
        this.bgc = false
      }
    },
    setText (obj) {
        // 数据绑定
        this.globData = obj;
      }
  },
  watch: {
    '$route': 'urlChange'
  },
    mounted() {
      var _this = this
      getDicsList(function (e) {
        _this.setText(e)
      })
    }
}
</script>
<style scoped lang="scss">
.footer {
  background: #FFFFFF;

  .footer_box {

    padding: 0;

    .content {
      .slide {
        border: 8px solid rgba(77, 147, 216, 0.26);
        padding: 30px;
        background: #FFFFFF;
        color: #212529;
        margin: auto;
        .line-top {
          margin-top: 20px;
          margin-bottom: 20px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        .logo {
          margin-top: 0px;
        }

        .qrCodeImg {
          margin-top: 15px;
        }

        .right_content {
          margin-top: 0px;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          padding-left: 52px;
        }
      }
    }
  }
}

.bgc_blue {
  background: #4d93d8;
  color: #FFFFFF;
}

.footer_EN {
  background: linear-gradient(180deg, #44DDD5, #0385EC);
  padding-top: 175px;
  padding-bottom: 87px;
  color: #FFFFFF;

  h2 {
    padding: 5px 20px 5px 12px;
    border: 1px solid #FFFFFF;
    text-align: center;
    font-size: 32px;
    display: inline-block;
    margin-bottom: 30px;
    font-family: SourceHanSerifSC;
  }

  .address {
    font-size: 14px;

    p {
      line-height: 35px;
    }

    font {
      opacity: 0.8;
    }

    .b_b {
      span {
        opacity: 0.8;
        padding-bottom: 4px;
        border-bottom: 1px solid #FFFFFF;
      }

    }
  }

  h1 {

    font-size: 60px;
    font-family: SourceHanSerifSC;
    font-weight: 800;
  }

  .qrImg {
    margin-top: 30px;
    justify-content: flex-end;
    align-items: flex-end;

    span {
      display: inline-block;
      padding-left: 15px;
      border-left: 8px solid #F4D785;
      font-size: 24px;
      font-family: SourceHanSerifSC;
      font-weight: 800;
      margin-right: 10px;
    }

    a {
      margin-right: 8px;
      background: #FFFFFF;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }

  .border_bottom {
    border-bottom: 1px solid #FFFFFF;
    margin: 52px 40px;
  }

  .copyright {
    text-align: center;
    line-height: 34px;
    width: 88%;
    margin: auto;
    font-size: 14px;
  }
}
</style>
