<template>
	<div class="introduction">
		<div class="content">
      <b-container>
        <b-row class="w-m-t-30">
          <b-col class="txt-333 txt-s16">
            <div v-html="globData.group106.Disclaimer.value"></div>
          </b-col>
        </b-row>
      </b-container>
		</div>
	</div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
  data() {
    return {
      globData : false
    }
  },
  methods: {
    setText (obj) {
      // 数据绑定
      this.globData = obj;
      console.log(obj);
    },
  },
  mounted() {
    var _this = this
    getDicsList(function (e) {
      _this.setText(e)
    })
  }
}
</script>
<style scoped lang="scss">
.product_tab {
  border: 4px solid #42dcd6;
  margin-top: 30px;
  margin-bottom: 30px;
  ul {
    li {
      // width: 25%;
      color: #B3B4B4;
      font-size: 16px;
      margin-right: 80px;
      cursor: pointer;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      .icon_border {
        display: inline-block;
        border: 2px solid #35c9da;
        background: #FFFFFF;
        width: 13px;
        height: 13px;
        border-radius: 100%;
      }
      .icon_border_active {
        background: #35c9da;
      }
      &:nth-child(1){
        &:before {
          content: '';
          width: 4px;
          height: 130%;
          position: absolute;
          top: -12px;
          right: -37px;
          background: #35c9da;
          transform: rotate(142deg);
        }
      }
    }
    .active {
      color: #35C9DA;
    }
    a {
      display: inline-block;
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}

.line_li_gr {
  span {
    display: inline-block;
    width: 89px;
    height: 6px;
    background: linear-gradient(270deg, #1A97E0, #42DBD6);
  }

}
</style>
