<template>
  <div class="productService w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <div class="max-width">
          <div class="w-m-t-30 w-p-t-20">
            <b-row class="title_box">
              <b-row style="max-width: 1200px;width: 100%">
                <b-col cols="4" v-for="(item, index) in secondaryMenuList" :key="index" class="newsMenu w-txt-center"
                       :class="menuActive === item.type?'menuActive':''" @click="tabChange(item.type)">
                  {{item.title}}
                </b-col>
              </b-row>
              </b-row>
            <b-container>
              <div v-for="(item, index) in newsList" :key="index">
                <b-row class="newsList bg_b" v-if="index%2 === 0">
                  <b-col sm="10" md="4" lg="4" class="w-flex">

                    <router-link :to="{path: 'productDetail', query:{id: item.id}}">
                      <b-img-lazy :src="item.cover" alt="" class="w-w-full"></b-img-lazy>
                    </router-link>
                    <div class="w-absolute more">
                      <router-link :to="{path: 'productDetail', query:{id: item.id, name : encodeURI(item.name)}}" class="link">了解更多</router-link>
                    </div>
                  </b-col>
                  <b-col class="txt-333 txt-s16 w-p-t-20">
                    <h2 class="w-m-b-20 txt-s20">
                      <router-link :to="{path: 'productDetail', query:{id: item.id}}">{{item.name}}</router-link>
                    </h2>
                    <div class="newsContent w-txt-index w-line-30" v-html="item.intro">

                    </div>
                  </b-col>
                </b-row>
                <b-row class="newsList" v-else>

                  <b-col class="txt-333 txt-s16 w-p-t-20">
                    <h2 class="w-m-b-20 txt-s20">
                      <router-link :to="{path: 'productDetail', query:{id: item.id}}">{{item.name}}</router-link>
                    </h2>
                    <div class="newsContent w-txt-index w-line-30" v-html="item.intro">

                    </div>
                  </b-col>
                  <b-col sm="10" md="4" lg="4" class="w-flex">

                    <router-link :to="{path: 'productDetail', query:{id: item.id}}">
                      <b-img-lazy :src="item.cover" alt="" class="w-w-full"></b-img-lazy>
                    </router-link>
                    <div class="w-absolute more">
                      <router-link :to="{path: 'productDetail', query:{id: item.id, name : encodeURI(item.name)}}" class="link">了解更多</router-link>
                    </div>
                  </b-col>
                </b-row>
              </div>

            </b-container>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
import { getDicsList } from '@/utils/index.js'
export default {
  data () {
    return {
      secondaryMenuList:[
        {
          title: "轨道交通电缆",
          type: 1
        },
        {
          title: "电力电缆",
          type: 2
        },
        {
          title: "数据通信电缆",
          type: 3
        },
        {
          title: "橡胶电缆",
          type: 4
        }
      ],
      menuActive: 1,
      hideNewsMenuList: 0,
      newsList: [],
      total: 0, // 总条数
      pageSize: 100, // 条数大小
      currentPage: 1, // 当前页
        globData : false
    }
  },
  watch: {
    '$route': 'getNewsType'
  },
  created () {

  },
  methods: {
    // 获取新闻列表
    getNewsList (PageIndex) {
      http.request('Product.ClassifyList', {
        PageIndex: PageIndex,
        PageSize: this.pageSize
      }, (res) => {
        if (res.flag == 200) {
          for (let item of res.data) {
            item.created_at = timestampToTime(item.created_at)
          }
          this.newsList = res.data
          this.total = res.data.total
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    pageChange (currentPage) {
      this.getNewsList(currentPage, this.newsType)
    },
    getNewsType () {
      this.menuActive = parseInt(this.$route.query.type) || this.newsType || 1
      this.hideNewsMenuList = parseInt(this.$route.query.show)
    },
    urlChange () {
      this.getNewsType()
      this.getNewsList(1, parseInt(this.$route.query.type) || this.newsType || 1)
    },
     setText (obj) {
         // 数据绑定
         this.globData = obj;
         console.log(obj);
       }
  },
  activated () {
    this.hideNewsMenuList = parseInt(this.$route.query.show)
  },
  mounted () {
    this.getNewsList(this.currentPage, this.$route.query.type)
    this.menuActive = parseInt(this.$route.query.type) || 1
    console.log(this.menuActive)
  }
}
</script>
<style scoped lang="scss">
.newsList {
  padding: 18px 50px;
  margin-bottom: 20px;
  h2 {
    color: #6699CC;
    font-weight: bold;
  }
  .more {
    right: 0;
    bottom: 20px;
    .link {
      color: #FFFFFF;
      font-size: 16px;
      display: inline-block;
      padding: 3px 15px;
      background: #4D93D8;
      border-radius: 15px;
    }
  }
}
.w-bg_f {
  padding-left: 105px;
  padding-right: 90px;
}
.bg_b {
  background: #E3F1FF;
}
.newsContent {
  ::v-deep p {
    line-height: 30px;
  }
}
</style>
