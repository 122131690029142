<template>
  <div class="search">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <div>
          <b-carousel id="carousel-fade" indicators img-width="100%" img-height="480" controls>
            <b-carousel-slide :img-src="item.banner" v-for="(item, index) in bannerList" :key="index"></b-carousel-slide>
          </b-carousel>
        </div>
<div class="search-box container ">
            <input type="text" placeholder="请输入..." name="" id="" value="" />
            <button><i>搜索</i></button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    created() {

    },
    methods: {

    },
    mounted() {

    }
  }
</script>
<style scoped lang="scss">
  .title {
    color: #0f649b;
  }

  .search-box {
    width: 100%;
    margin: 50px auto;
    display: flex;
    /*border: 1px solid red;*/
  }

  .search-box input {
    float: left; //左浮动
    flex: 4;
    height: 30px;
    outline: none;
    border: 1px solid #4D93D8;
    box-sizing: border-box; //盒子模型，怪异IE盒子模型，width=content+border*2+padding*2
    padding-left: 10px;
  }

  .search-box button {
    float: right;
    flex: 1;
    height: 30px;
    background-color: #4D93D8;
    color: white;
    border-style: none;
    outline: none;
  }

  .search-box button i {
    font-style: normal;
  }

  .search-box button:hover {
    font-size: 16px;
  }
</style>
