/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 *
 *
 */

let baseUrl
    // 环境的切换
if (process.env.NODE_ENV == 'development') {
    baseUrl = window.location.protocol + '//api.dq.dongqiang.com/?service='
} else if (process.env.NODE_ENV == 'debug') {
    baseUrl = window.location.protocol + '//api.dq.dongqiang.com/?service='
} else if (process.env.NODE_ENV == 'production') {
    baseUrl = window.location.protocol + '//api.dq.dongqiang.com/?service='
}

let routerMode = 'hash'

export {
	baseUrl,
	routerMode
}
