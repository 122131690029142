<template>
	<div class="home">
		<!-- <div class="lineStyleText" v-if="mapCompanyOpacity" :style="lineStyleText1"></div>
    <div class="lineStyleText" v-if="mapCompanyOpacity" :style="lineStyleText2"></div>
    <div class="lineStyleText" v-if="mapCompanyOpacity" :style="lineStyleText3"></div> -->
		<div class="w-absolute map_line" v-if="mapCompanyOpacity" :style="lineStylePosition"></div>
		<div class="w-absolute map_line" v-if="mapCompanyOpacity" :style="lineStylePosition1"></div>
		<div class="content w-w-full">
      <b-row class="bg1">
        <div class="block-title">
          <h1 class="block-title-bg">NEWS</h1>
          <h5 class="block-title-text">最新动态</h5>
        </div>
        <b-container class="bv-example-row w-p-t-30">
            <el-carousel :interval="5000" arrow="always" :autoplay="true" :loop="true" indicators :height="newsHeight1">
              <el-carousel-item v-for="(item, index) in newsDynamic" :key="'ind1'+index">
                <b-row class="w-m-t-30" style="width:95%;margin-left:2.5%;">
                    <b-col class="col-sm-12 col-md-6 col-lg-6" v-for="(val, ind) in item" :key="'ind2'+ind">
                      <router-link :to="{path : '/chinese/news/newsDetail?id='+val.id}">
                      <div class="news-item">
                        <img :src="val.images" :alt="val.images"  class="hoverImg">
                        <h1 class="title">
                          {{val.title}}
                        </h1>
                        <p>
                          {{val.describe}}
                        </p>
                        <span class="addtime">{{timestampToTime(val.created_at)}}</span>
                      </div>
                      </router-link>
                    </b-col>
                </b-row>
              </el-carousel-item>
            </el-carousel>
        </b-container>
      </b-row>
			<b-container class="txt_introduce bv-example-row w-p-t-30 txt-666">
				<b-row class="w-m-b-30 w-row-center">
					<div class="w-flex more w-row-center w-m-t-15">
						<router-link to="/chinese/news/newsCenter?type=1">了解更多</router-link>
					</div>
				</b-row>
			</b-container>
		</div>
		<div class="content w-w-full">
      <b-row>
        <div class="block-title">
          <h1 class="block-title-bg">MEDIA</h1>
          <h5 class="block-title-text">媒体关注</h5>
        </div>
      </b-row>
			<b-container class="bv-example-row w-p-t-30">
				<b-row class="w-m-t-30 justify-content-center">
          <div class="col-md-6">

						<el-carousel :interval="5000" arrow="always" style="display: contents" height="351px">
							<el-carousel-item v-for="(item3, index3) in mediaCoverage" :key="'ind3'+index3"  style="height:calc(100% - 12px)">
								<div class="box">
									<router-link :to="{path : '/chinese/news/newsDetail?id='+item3.id}">
										<img class="transition-box" :src="item3.images" alt="">
									<div class="title">
										{{item3.title}}
									</div>
									</router-link>
								</div>
							</el-carousel-item>
						</el-carousel>

					</div>
					<div class="col-md-6">
						<b-row  class="news-line" v-for="(item4, index4) in mediaCoverageRight" :key="'ind4'+index4" @click="detail(item4)" style="cursor: pointer">

							<b-col cols="2" class="left">
								<span class="date">
									{{getDay(item4.created_at)}}
								</span>
								<p>
									{{getMonth(item4.created_at)}}
								</p>
							</b-col>
							<b-col cols="10" class="right">
								<span class="title">
									{{item4.title}}
								</span>
								<p class="desc">
									{{item4.describe}}
								</p>
							</b-col>
						</b-row>
					</div>
				</b-row>
			</b-container>
			<b-container class="txt_introduce bv-example-row w-p-t-30 txt-666">
				<b-row class="w-m-b-30 w-row-center" >
					<div class="w-flex more w-row-center w-m-t-15">
						<router-link to="/chinese/news/newsCenter?type=2">了解更多</router-link>
					</div>
				</b-row>
			</b-container>
		</div>
		<div class="content w-w-full">
      <b-row class="bg2">
			<b-container class="bv-example-row w-p-t-30">
				<b-row>
					<div class="block-title">
						<h1 class="block-title-bg">STORY</h1>
						<h5 class="block-title-text">品牌故事</h5>
					</div>
				</b-row>
				<el-carousel :interval="5000" arrow="always" :autoplay="false" :loop="true" indicators :height="newsHeight" style="margin-top: 50px">
					<el-carousel-item v-for="(item2, index2) in brandStory" :key="'ind2'+index2">
						<b-row class="w-m-t-30" style="width:95%;margin-left:2.5%;">
								<b-col class="col-sm-12 col-md-4 col-lg-4" v-for="(val2, ind2) in item2" :key="'ind2'+ind2">
									<router-link :to="{path : '/chinese/news/newsDetail?id='+val2.id}">
									<div class="news-item1">
										<img :src="val2.images" :alt="val2.images" class="hoverImg">
										<h1 class="title">
											{{val2.title}}
										</h1>
										<p>
											{{val2.describe}}
										</p>
										<span class="addtime">{{timestampToTime(val2.created_at)}}</span>
									</div>
									</router-link>
								</b-col>
						</b-row>
					</el-carousel-item>
				</el-carousel>

			</b-container>
      </b-row>
			<b-container class="txt_introduce bv-example-row w-p-t-30 txt-666">
				<b-row class="w-m-b-30 w-row-center">
					<div class="w-flex more w-row-center w-m-t-15">
						<router-link to="/chinese/news/newsCenter?type=3">了解更多</router-link>
					</div>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
	import http from '@/http/http'
	import {
		getDicsList
	} from '@/utils/index.js'
	import Swiper from 'swiper'
	import { timestampToTime } from '@/utils'
	import 'element-ui/lib/theme-chalk/base.css';
	export default {
		data() {
			return {
				timestampToTime :timestampToTime,
				title: '首页',
				picHeight: 0,
				mediaCoverage: false, // 媒体报道
				mediaCoverageRight : false,
				newsDynamic: false, // 集团动态
				brandStory: false,
				dialogDetail: false,
				videoHeight: 0,
				globData: false,
				thisDynamic: 0,
				thisNewsDynamic: 0,
				thisDynamicImgHeight: '0',
				mapCompanyOpacity: 1,
				lineStyleText1: "",
				lineStyleText2: "",
				lineStyleText3: "",
				cityId: "",
				lineStylePosition: '',
				lineStylePosition1: '',
        newsHeight:"400px",
        newsHeight1:"465px",
			}
		},
		computed: {
			getMonth(){
				return val => {
					var date = new Date(val * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
					var Y = date.getFullYear() ;
					var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
					return Y + '-' + M;
				}
			},
			getDay() {
				return val => {
					var date = new Date(val * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
					var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
					return D;
				};
			},
		},
		methods: {
			detail(item){
				this.$router.push({
					path: '/chinese/news/newsDetail?id='+item.id
				});
			},
			prev() {
				this.$refs.swiper.swiper.slidePrev();
			},
			next() {
				this.$refs.swiper.swiper.slideNext();
			},
			// 新闻
			newsListCenter() {
				http.request('News.GetRecommend', {}, (res) => {
					if (res.flag == 200) {
						this.mediaCoverage = res.data.media_coverage.slice(0,4)
						this.mediaCoverageRight = res.data.media_coverage.slice(4, 8)
						this.newsDynamic = res.data.news_dynamic
						this.brandStory = res.data.brand_story

					} else {
						this.$message({
							message: data.msg,
							type: 'error'
						})
					}
				})
			},

			imgLoad() {
				this.$nextTick(() => {
					this.videoHeight = this.$refs.picHeight.height
					this.picHeight = this.$refs.picHeight.height / 2 - 2.5
				})
			},
			playVideo() {
				this.dialogDetail = true
				if (this.$refs.vueMiniPlayer) this.$refs.vueMiniPlayer.play()
			},
			pauseVideo() {
				this.$refs.vueMiniPlayer.pause()
				this.dialogDetail = false
			},
			addMe() {
				this.$router.push({
					path: '/chinese/gather/gather'
				})
			},
			setText(obj) {
				// 数据绑定
				this.globData = obj
			}
		},
		mounted() {
			var _this = this
			var swiper = new Swiper('.swiper-container', {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 30,
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination'
				}
			});
			window.addEventListener('resize', () => {
        if (document.body.offsetWidth < 750) {
          this.newsHeight = "1100px"
        } else {
          this.newsHeight = "400px"
        }
			})
			if (document.body.offsetWidth < 750) {
				this.newsHeight = "1100px"
			}
			this.thisDynamicImgHeight = '217px'
			this.newsListCenter()

			getDicsList(function(e) {
				_this.setText(e)
			})
		},
		created() {}
	}
</script>
<style scoped lang="scss">
	@import "./../../../../static/css/core.css";
	@import "./../../../../static/css/news.scss";

	$yellow: #FFAC08;
	$blue: #0f649b;

	.swiper-slide {
		width: auto;
		padding: 0 20px;
	}

	.moreHover {
		color: $blue;
		border: 1px solid #FFFFFF;
		background: #FFFFFF;
	}

	.hoverImg {
		transition: all .3s;
	}

	.hoverImg:hover {
		transform: scale(1.1);
	}

	.home {
		.content {
			.txt_introduce {
				p {
					padding-left: 20px;
					text-indent: 2em;

					font-family: SourceHanSansCN;
				}

				.line {
					line-height: 40px;
				}
			}

			.company_profile {
				background: $blue;

				.icon_y {
					display: inline-block;
					width: 10px;
					height: 10px;
					border-radius: 100%;

				}
			}

			.more {
				a {
					display: inline-block;
					text-align: center;
					width: 127px;
					height: 32px;
					line-height: 32px;
					border: 1px solid #5984C8;
					border-radius: 5px;
					color: #5984C8;
					cursor: pointer;

					&:hover {
						background: $blue;
						color: #FFFFFF;
						border-color: $blue;
					}
				}
			}

			.service {
				background: #0f649b;

				.more {
					a {
						border: 1px solid #FFFFFF;
						color: #FFFFFF;

						&:hover {
							@extend .moreHover;
						}
					}
				}
			}

			.title {
				h2 {
					color: #4D93D8;
				}
			}
			.news {
				background: #237dd7;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					width: 50%;
					height: 100%;
					top: 0;
					background: #0f649b;
					right: 0;
				}

				.mediaCoverage {
					.title {
						padding-left: 20px;

						&:after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 8px;
							height: 100%;
							background: #f5dd9b;
						}
					}

					.newsDynamicContent {
						::v-deep img {
							width: 100%;
						}

					}

					.more {
						padding: 6px 20px;
						border-radius: 8px;
						border: 2px solid #FFFFFF;
						cursor: pointer;

						&:hover {
							@extend .moreHover;
						}
					}
				}
			}
		}
	}

	.report {
		width: 90px;
		background-color: #D3D3D3;
		position: fixed;
		bottom: 20%;
		right: 10px;
		z-index: 5;
		border-radius: 10px;
		text-align: center;

		padding: 10px 0;

		.separate {
			width: 90%;
			height: 1px;
			background-color: #A0A0A0;
			margin: 10px 5% 10px 5%;
		}

		img {
			cursor: pointer;
		}
	}

	.icon_video {
		width: 50px;
		left: 50%;
		margin-left: -25px;
	}

	::v-deep .el-carousel__arrow {
		border-radius: 0;
		background: rgba(77, 147, 216, 1);
		opacity: 0.39;
		width: 23px;
		height: 39px;
	}

	::v-deep .el-carousel__arrow--right {
		right: 0;
	}

	::v-deep .el-carousel__arrow--left {
		left: 0;
	}

	::v-deep .el-carousel__indicators {
		display: none;
	}

	.lg-r {
		margin-right: 7%;
	}
</style>
