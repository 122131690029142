// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
// import Vue from 'vue/dist/vue.esm.js'
import App from './App'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import BootstrapVue from 'bootstrap-vue'
import 'swiper/css';

import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../static/css/reset.css'
import '../static/css/common.css'
import 'babel-polyfill'

import {
  dispatchEventStroage,
  getStorge,
  getGlobSettingTop,
  getDicsList,
} from "@/utils/index.js"

import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.use(ElementUI)
Vue.use(BootstrapVue)

/* 请求拦截器 */
axios.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token')
      // eslint-disable-next-line no-useless-escape
      .replace(/(^\")|(\"$)/g, '')
  }
  return config
}, err => {
  return Promise.reject(err)
})
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue()
/* eslint-disable no-new */

getDicsList(function(){

}, true);

NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();

  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next();
});
dispatchEventStroage();

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
new Vue({
  el: '#app',
  router,
  components: {
    App
  },
  template: '<App/>'
})
