<template>
  <div class="head">
    <div class="logo_wrap txt-s16">
      <div class :class="EN?' nav_width':''">
        <b-navbar toggleable="lg" type="dark" class="p-z">
          <b-navbar-brand :href="EN ? '/#/chinese/home/home' : '/#/english/home/home'">
            <img v-if="EN" class="web-logo" src="@/assets/images/logo.png"/>
            <img v-else class="web-logo" src="@/assets/images/en_logo.png"/>
            <span v-if="EN" class="web-name">江苏东强股份有限公司</span>
            <span v-else class="web-name">Jiangsu Dongqiang Co., Ltd.</span>
          </b-navbar-brand>

          <b-navbar-toggle id="navbar_toggle" target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav v-if="EN">
            <b-navbar-nav class="ml-auto w-flex nav-par">
              <b-navbar-brand
                :to="item.path"
                v-for="(item, index) in tabMenuList"
                class="line-h"
                :key="index"
                @mouseenter="showMenu(item.show, index)"
                @mouseleave="showMenu(item.show, index)"
              >
                <div v-if="!item.secondaryMenu">
                  <span class="menuName" :class="rootMenuActive === item.path?'rootMenuActive':''">{{ item.name }}</span>
                </div>
                <div class="w-pointer w-relative w-txt-center two-menu" v-else @mouseover="hideSeachInput">
                  <span class="menuName" :class="rootMenuActive === item.path?'rootMenuActive':''">{{ item.name }}</span>

                  <div class="w-absolute secondaryMenu" v-if="item.show">
                    <ul>
                      <li v-for="(slide, twoIndex) in item.secondaryMenu" :key="twoIndex">
                        <router-link
                          :to="{path: slide.path, query:{type: slide.type}}"
                          @click="goNews(slide.type)"
                        >{{ slide.name }}</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </b-navbar-brand>

              <b-navbar-brand class="w-flex mr-auto w-relative">
                <img
                  src="../assets/images/white_search.png"
                  @click="redirect('/chinese/search/search')"
                  alt
                  width="27px"
                  height="27px"
                />
                <div class="header_search" v-if="headerSearch">
                  <select name="" id="" v-model="newsOrProductValue" style="width:100px;height:38px;border: 2px solid #eeeeee;border-right:none;text-align: center;text-align-last: center;">
                    <option value="1">新闻</option>
                    <option value="2">产品</option>
                  </select>
                  <input type="text" v-model="headerInput" />
                  <span class="search-btn" @click="headerSearchChange">确定</span>
                </div>
                <div class="w-p-l-15 zh w-relative w-m-l-10">
                  <img
                    src="../assets/images/zh.png"
                    alt
                    width="37px"
                    height="27px"
                    @click="LanguageConversion(EN)"
                  />
                  <!-- <img src="../assets/images/zh.png" alt="" width="37px" height="27px"/> -->
                </div>
              </b-navbar-brand>
            </b-navbar-nav>
          </b-collapse>
          <b-collapse id="nav-collapse" is-nav v-else>
            <b-navbar-nav class="ml-auto w-flex nav-par english_nav">
              <b-navbar-brand
                :to="item.path"
                v-for="(item, index) in EnMenuLis"
                class="line-h"
                :key="index"
              >
                <div>
                  <span class="menuName">{{ item.name.toUpperCase() }}</span>
                </div>
              </b-navbar-brand>

              <b-navbar-brand class="w-flex mr-auto">
                <!-- <img src="../assets/images/white_search.png" @click="redirect('search')" alt="" width="27px" height="27px"> -->
                <div class="w-p-l-15 zh w-relative w-m-l-10">
                  <img
                    src="../assets/images/zh.png"
                    alt
                    width="37px"
                    height="27px"
                    @click="LanguageConversion(EN)"
                  />
                  <!-- <img src="../assets/images/zh.png" alt="" width="37px" height="27px"/> -->
                </div>
              </b-navbar-brand>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <div class="banner_wrap w-relative" v-if="bannerShowStatus">
      <b-carousel id="carousel-fade" indicators img-width="100%" img-height="480" controls>
        <b-carousel-slide :img-src="item.image" v-for="(item, index) in bannerList" :key="index" :data-type="item.link_type">
          <div class="see_more" v-if="item.link && item.link.lemgth > 0">
            <a v-if="item.link_type == 1" :href="item.link" target="_blank">
              <span class="banner_text" v-if="item.text">{{item.banner_text}}</span>
              <span v-else>了解更多</span>
              </a>
            <router-link v-if="item.link_type == 2" :to="item.link"><span class="banner_text" v-if="item.text">{{item.banner_text}}</span>
              <span v-else>了解更多</span></router-link>
          </div>
          <div class="banner_text" v-if="item.banner_text">{{item.banner_text}}</div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div v-else class="w-relative">
      <img src="@/assets/images/bg_h.png" width="100" height="90" class="w-100 d-block">
    </div>
  </div>
</template>

<script>
import http from "@/http/http";
import { getStorge, getGlobSettingTop, getDicsList } from "@/utils/index.js";
export default {
  inject: ['reload'],
  data() {
    return {
      search: "",
      rootMenuActive:'/chinese/home/home',
      tabActive: 1,
      bannerList: [],
      tabMenuList: [
        {
          name: "首页",
          type: 1,
          path: "/chinese/home/home"
        },
        {
          name: "关于东强",
          type: 2,
          path: "/chinese/pages/introduce",
          show: false,
          secondaryMenu: [
            {
              name: "领导致辞",
              path: "/chinese/pages/introduce",
              type: 1
            },
            {
              name: "企业概况",
              path: "/chinese/pages/introduce",
              type: 2
            },
            {
              name: "发展历程",
              path: "/chinese/pages/introduce",
              type: 3
            },
            // {
            //   name: "厂区新貌",
            //   path: "/chinese/pages/introduce",
            //   type: 4
            // },
            {
              name: "荣誉资质",
              path: "/chinese/pages/introduce",
              type: 5
            }
          ]
        },
        {
          name: "领导团队",
          type: 2,
          path: "/chinese/pages/leader",
          show: false,
          secondaryMenu: [
            {
              name: "领导团队",
              path: "/chinese/pages/leader",
              type: 1
            },
            {
              name: "党委书记荣誉",
              path: "/chinese/pages/leader",
              type: 2
            },
            // {
            //   name: "管理机构",
            //   path: "/chinese/pages/leader",
            //   type: 3
            // }
          ]
        },
        {
          name: "新闻中心",
          type: 3,
          path: "/chinese/news/newsCenter",
          show: false,
          secondaryMenu: [
            {
              name: "最新动态",
              path: "/chinese/news/newsCenter",
              type: 1
            },
            {
              name: "媒体关注",
              path: "/chinese/news/newsCenter",
              type: 2
            },
            {
              name: "品牌故事",
              path: "/chinese/news/newsCenter",
              type: 3
            }
          ]
        },
        {
          name: "产品中心",
          type: 4,
          path: "/chinese/productService/productDetail",
          show: false,
          secondaryMenu: [
            {
              name: "轨道交通电缆",
              path: "/chinese/productService/productDetail",
              type: 1
            },
            {
              name: "电力电缆",
              path: "/chinese/productService/productDetail",
              type: 2
            },
            {
              name: "数据通信电缆",
              path: "/chinese/productService/productDetail",
              type: 3
            },
            {
              name: "橡胶电缆",
              path: "/chinese/productService/productDetail",
              type: 4
            }
          ]
        },
        {
          name: "生产与研发",
          type: 5,
          path: "/chinese/pages/qualityInnovation",
          show: false,
          secondaryMenu: [
            {
              name: "研发中心",
              path: "/chinese/pages/qualityInnovation",
              type: 1
            },
            {
              name: "体系认证",
              path: "/chinese/pages/qualityInnovation",
              type: 2
            },
            {
              name: "生产设备",
              path: "/chinese/pages/qualityInnovation",
              type: 3
            },
            {
              name: "检测设备",
              path: "/chinese/pages/qualityInnovation",
              type: 4
            }
          ]
        },
        {
          name: "企业文化",
          type: 6,
          path: "/chinese/pages/corporateCulture",
          show: false,
          secondaryMenu: [
            {
              name: "党委书记理念",
              path: "/chinese/pages/corporateCulture",
              type: 1
            },
            {
              name: "发展战略",
              path: "/chinese/pages/corporateCulture",
              type: 2
            },
            {
              name: "企业精神",
              path: "/chinese/pages/corporateCulture",
              type: 3
            },
            {
              name: "孔子文化园",
              path: "/chinese/pages/corporateCulture",
              type: 4
            },
            {
              name: "东强之歌",
              path: "/chinese/pages/corporateCulture",
              type: 5
            }
          ]
        },
        {
          name: "党的建设",
          type: 7,
          path: "/chinese/pages/gardenPlot",
          show: false,
          secondaryMenu: [
            {
              name: "党组织机构",
              path: "/chinese/pages/gardenPlot",
              type: 1
            },
            {
              name: "党建活动",
              path: "/chinese/pages/gardenPlot",
              type: 2
            }
          ]
        },
        {
          name: "社会责任",
          type: 8,
          path: "/chinese/pages/socialResponsibility",
          show: false,
          secondaryMenu: [
            {
              name: "责任报告",
              path: "/chinese/pages/socialResponsibility",
              type: 1
            },
            {
              name: "东强奖学金",
              path: "/chinese/pages/socialResponsibility",
              type: 2
            },
            {
              name: "慈善事业",
              path: "/chinese/pages/socialResponsibility",
              type: 3
            }
          ]
        }
      ],
      EN: true,
      EnMenuLis: [
        {
          name: "About DQ cables",
          type: 1,
          path: "/english/aboutUs/introduction"
        },
        {
          name: "Product Center",
          type: 2,
          path: "/english/productCenter/product"
        },
        {
          name: "Equipment",
          type: 3,
          path: "/english/equipment/equipment"
        },
        {
          name: "Qualification",
          type: 4,
          path: "/english/qualification/qualification"
        },
        {
          name: "Service",
          type: 5,
          path: "/english/service/service"
        },
        {
          name: "Contact",
          type: 6,
          path: "/english/contactUs/contactUs"
        },
        {
          name: "Disclaimer",
          type: 7,
          path: "/english/disclaimer/disclaimer"
        }
      ],
      headerSearch: false,
      headerInput: "",
      bannerShowStatus: 1,
      globData: false,
      globData2: false,
      newsOrProduct: [
        {
          type: 1,
          value: '资讯'
        },
        {
          type: 2,
          value: '产品'
        }
      ],
      newsOrProductValue: false
    };
  },
  created() {
    var thisIsShow = this.$route.name == "/chinese/productService/productDetail" ? 2 : 1;
    var thisHead = this.$route.query.header || false;

    if(thisIsShow && thisHead){
      this.headerInput = thisHead;
      this.newsOrProductValue = thisIsShow;
    }
  },
  methods: {
    tabChange(type, url) {
      this.tabActive = type;
      this.$router.push({
        path: url
      });
    },
    LanguageConversion(b) {
      this.EN = !b;
      if (b) {
        this.$router.push({
          path: "/english/aboutUs/introduction"
        });
      } else {
        this.$router.push({
          path: "/chinese/home/home"
        });
      }
    },
    redirect(url) {
      this.headerSearch = !this.headerSearch;
      // return this.$router.push({
      //   path: url
      // })
      if (document.body.offsetWidth < 750) {
        this.headerSearch = false
        this.$alert('', '搜索', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          customClass: 'searchDialog',
          showInput: true,
          callback: (action, instance) => {
            if (instance.inputValue !== '' && instance.inputValue !== null) {
              this.$router.push({
                path: "/chinese/news/newsCenter?header=" + instance.inputValue + "&show=1"
              });
            }
          },
          inputValidator: val => {
            console.log(val)
          },
          inputErrorMessage: '不能为空'
        }).then(({ value }) => {
                //点击确定需要执行的方法
                console.log(value)

        });
      }
    },
    showMenu(show, index) {
      this.tabMenuList[index].show = !this.tabMenuList[index].show;
    },
    // 获取banner
    getBannerSet() {},
    urlChange() {
      var En = this.$route.name.substr(1, 7) == "chinese";
      this.rootMenuActive = this.$route.name;
      console.log(this.rootMenuActive)
      this.EN = En;
      if (!En) {
        document.title = 'Jiangsu Dongqiang Co., Ltd.'
      } else {
        document.title = '江苏东强股份有限公司'
      }
      // this.getBannerSet()
      window.scrollTo(0, 0);
      this.globDataFunc();
      // 手机上点击导航栏，初始化导航栏
      if (document.getElementById("navbar_toggle").getAttribute("aria-expanded") === 'true') {
        this.reload()
      }
    },
    goNews(type) {
      this.$eventHub.$emit("newsType", type);
    },
    headerSearchChange() {
      // var oldWord = this.$route.query.header || "";
      // var oldType = this.$route.query.show || 1;
      // var thisSearchType = this.newsOrProductValue ? parseInt(this.newsOrProductValue) : 1;
      // if (oldWord.trim() == this.headerInput.trim() && oldType == thisSearchType) return false;
      if (this.headerInput === "") {
        this.$message({
          message: "请输入内容",
          type: "error"
        });
        return;
      }


      if (parseInt(this.newsOrProductValue) === 2) {
        let url = "/chinese/productService/productDetail?header=" + encodeURIComponent(this.headerInput) + "&show=1";
        if(this.$route.fullPath == url) return false;
        this.$router.push({
          path: url
        });
        return false;
      } else {
        let url = "/chinese/news/newsCenter?header=" + encodeURIComponent(this.headerInput) + "&show=1";
        console.log(this.$route.fullPath == url)
        if(this.$route.fullPath == url) return false;
        this.$router.push({
          path: url
        });
        return false;
      }

      this.headerSearch = false;
    },
    globDataFunc() {
      var _this = this;
      getDicsList(function(res) {
        _this.globData = res;
        _this.setBanner();
      });
    },
    findStr(str, cha, num) {
      var x = str.indexOf(cha);
      for (var i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
      }
      return x;
    },
    setBanner() {
      var banner = [];
      var thisPath = this.$route.name
      var bannerShowStatus = 1;
      switch (thisPath) {
        case "/chinese/news/newsDetail":
          bannerShowStatus = 0;
          banner = this.getTypeBanners(3);
        case "/chinese/home/home":
          banner = this.getTypeBanners(1);
          break;
        case "/chinese/pages/introduce":
          banner = this.getTypeBanners(2);
          break;
        case "/chinese/news/newsCenter":
          banner = this.getTypeBanners(3);
          break;
        case "/chinese/productService/productDetail":
          banner = this.getTypeBanners(4);
          break;
        case "/chinese/pages/qualityInnovation":
          banner = this.getTypeBanners(5);
          break;
        case "/chinese/pages/corporateCulture":
          banner = this.getTypeBanners(6);
          break;
        case "/chinese/pages/gardenPlot":
          banner = this.getTypeBanners(7);
          break;
        case "/chinese/pages/socialResponsibility":
          banner = this.getTypeBanners(8);
          break;
        case "/english/home/home":
          banner = this.getTypeBanners(110);
          break;
        case "/english/aboutUs/introduction":
          banner = this.getTypeBanners(100);
          break;
        case  "/english/productCenter/product":
          banner = this.getTypeBanners(101);
          break;
        case "/english/equipment/equipment":
          banner = this.getTypeBanners(102);
          break;
        case "/english/qualification/qualification":
          banner = this.getTypeBanners(103);
          break;
        case "/english/service/service":
          banner = this.getTypeBanners(104);
          break;
        case "/english/contactUs/contactUs":
          banner = this.getTypeBanners(105);
          break;
        case "/english/disclaimer/disclaimer":
          banner = this.getTypeBanners(106);
          break;
      }
      if(banner.length == 0) banner = this.getTypeBanners(1);

      for(var i in banner){
        var check = banner[i].link.substr(0, 4);
        if(check == "http"){
          //远程链接
          banner[i].link_type = 1;
        } else {
          //本地链接
          banner[i].link_type = 2;
        }
      }
      this.bannerShowStatus = bannerShowStatus;
      this.bannerList = banner;
    },
    getTypeBanners(type = 1) {
      var banners = [];
      if (this.globData && Object.keys(this.globData).length > 0) {
        banners = this.globData.banners || [];
      }

      var banner = [];
      for (var i in banners) {
        if (banners[i].type == type) {
          banner = banners[i].list;
        } else {
          continue;
        }
      }

      return banner;
    },
    setText(obj) {
      // 数据绑定
      this.globData2 = obj;
    },
    hideSeachInput(){
      if(this.headerSearch) this.headerSearch = false;
    }
  },
  mounted() {
    this.getBannerSet();
    this.urlChange();
    var _this = this;
    getDicsList(function(e) {
      _this.setText(e);
    });

    var searchType = this.newsOrProductValue ? parseInt(this.newsOrProductValue) : 1;
    this.newsOrProductValue = searchType;

  },
  watch: {
    $route: "urlChange"
  },
  activated() {
    // this.globDataFunc()
  }
};
</script>
<style scoped lang="scss">
.rootMenuActive{
  font-size: 18px;
  font-weight: 800;
}
$white: #ffffff;
.menuName {
  font-size: 16px;
}
.secondaryMenu {
  font-size: 16px !important;
}
.head {
  .logo_wrap {
    background: rgba(0, 0, 0, 0.15);
    width: 100%;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    border-bottom: 2px solid rgba(255,255,255,0.4);
    .nav_width {
    }
    .web-logo{
      max-height: 70px;
    }
    .web-name{
      font-size: 30px;
      font-weight: bold;
      vertical-align: middle;
      height: 90px;
    }
    @media (max-width: 750px) {
      .web-logo {
        max-height: 50px;
      }
      .web-name {
        font-size: 20px;
      }
    }

    .zh {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background: $white;
      }
    }

    .p-z {
      padding: 0 15px;
    }

    .line-h {
      line-height: 75px;
    }

    .secondaryMenu {
      top: 80px;
      left: -40px;
      text-align: center;
      background: #aec6eb;
      z-index: 20;
      font-size: 18px;
      line-height: initial;

      ul {
        padding: 0 15px;

        li {
          padding: 15px;
          border-bottom: 1px solid #d7e3f6;

          &:hover {
            color: #0f649b;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .bg-b {
        background: #0f649b;
        height: 15px;

        i {
          width: 0;
          height: 0;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid #0f649b;
          position: absolute;
          top: -10px;
          left: 50%;
          margin-left: -10px;
        }
      }

      .bg-h {
        background: #e3e7f4;
        height: 15px;
      }
    }
  }
}

.header_search {
  position: absolute;
  top: 47px;
  right: 0px;
  display: flex;
  align-items: center;
  .newsOrProduct{
    width: 100px;
  }
  input {
    line-height: 34px;
    border: 2px solid #eeeeee;
    width: 240px;
    padding: 0 15px;
  }

  span {
    width: 44px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #d3d3d3;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
  }
  .search-btn{
    background-color: #0086E5;
  }
}
</style>
