<template>
  <div class="productDetail w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <b-row class="title_box">
          <b-row style="max-width: 1200px;width: 100%">
          <b-col cols="3" v-for="(item, index) in secondaryMenuList" :key="index" class="newsMenu w-txt-center"
                 :class="menuActive === item.type?'menuActive':''" @click="tabChange(item.type)">
            {{item.name}}
          </b-col>
          </b-row>
        </b-row>
        <div class="max-width">
          <div class="w-p-t-20">
            <b-container class="w-m-t-30">
              <b-row >
                <b-col sm="10" md="4" lg="4" v-for="(item, index) in newsList" :key="index">
                  <div class="newsList">
                    <div class="pre-img">
                    <b-img-lazy :src="item.images" alt="" class="w-w-full product_pic hoverImg" ></b-img-lazy>
                    </div>
                    <h2 class="w-m-t-5 txt-s20 w-txt-center">{{item.title}}</h2>
                    <div class="w-line-30 w-m-b-30 txt-s14 proContent">{{item.intro}}</div>
                    <div class="w-absolute detail">

                      <span @click="shouDetailDialog(item.id)">详  细 </span>
                    </div>
                  </div>
                </b-col>

              </b-row>

            </b-container>
            <div class="w-flex w-row-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="pageSize"
                aria-controls="my-table"
                @input="pageChange(currentPage)"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog_style"
      :visible.sync="dialogDetail"
      width="80%">
      <div>
<!--        <div class="w-w-40 sm_none">-->
<!--          <img :src="productDetail.images" alt="" width="100%">-->
<!--        </div>-->
        <div class="w-w-100 w-p-l-20 " style="height: 500px;max-height: 500px; overflow: scroll;">
          <h3 class="txt-s24 w-m-b-30 w-p-t-20" style="text-align: center">{{productDetail.title}}</h3>
          <div class="w-line-30 w-p-r-50 w-p-b-20" v-html="productDetail.content"></div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDetail = false">取 消</el-button>
        <el-button type="primary" @click="dialogDetail = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
export default {
  data () {
    return {
      secondaryMenuList:[
        {
          name: "轨道交通电缆",
          type: 1
        },
        {
          name: "电力电缆",
          type: 2
        },
        {
          name: "数据通信电缆",
          type: 3
        },
        {
          name: "橡胶电缆",
          type: 4
        }
      ],
      menuActive: 1,
      newsType:1,
      newsList: [],
      total: 0, // 总条数
      pageSize: 12, // 条数大小
      currentPage: 1, // 当前页
      productInfo: '',
      productId: '',
      dialogDetail: false,
      productDetail: {},
      title: '产品中心',
      hideProductMenuList: 0
    }
  },
  created () {

  },
  methods: {
    // 获取新闻列表
    getNewsList (PageIndex) {
      http.request('Product.List', {
        Type: parseInt(this.$route.query.type || 1)+3,
        PageIndex: PageIndex,
        PageSize: this.pageSize,
        KeyWord: this.$route.query.header || ''
      }, (res) => {
        if (res.flag === 200) {
          for (let item of res.data.list) {
            item.created_at = timestampToTime(item.created_at)
            this.productInfo = item.title
          }
          this.newsList = res.data.list
          this.total = res.data.total
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    tabChange (index) {
      this.menuActive = this.newsType = index
      window.location.href = '/#/chinese/productService/productDetail?type=' + this.newsType
    },
    pageChange (currentPage) {
      this.getNewsList(currentPage)
    },
    // 显示详情弹窗
    shouDetailDialog (id) {
      this.dialogDetail = true
      this.getProductDetail(id)
    },
    getProductDetail (pid) {
      http.request('Product.Detail', {
        ProductId: pid
      }, (res) => {
        if (res.flag === 200) {
          this.productDetail = res.data.info
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    getNewsType () {
      this.menuActive = parseInt(this.$route.query.type) || this.newsType || 1
    },
    urlChange () {
      this.getNewsType()
      this.getNewsList(1)
    }
  },
  activated () {
    this.title = this.$route.query.name ? decodeURI(this.$route.query.name) : '产品服务'

    this.getNewsList(this.currentPage)
  },
  mounted () {
    this.getNewsList(this.currentPage, this.$route.query.type)
    this.menuActive = parseInt(this.$route.query.type) || 1
  },
  watch: {
    '$route': 'urlChange'
  }
}
</script>
<style scoped lang="scss">
.newsList {
  height: 330px;
  border: 1px solid #E1E1E1;
  margin-bottom: 50px;
  padding: 8px;
  .pre-img {
    height: 180px;
  }
  h2 {
    color: #6699CC;
    font-weight: bold;
    overflow: hidden;
    height: 55px !important;
    padding-top: 30px;
  }
  .detail {
    bottom: 37px;
    left: 0;
    text-align: center;
    width: 100%;
    span {
      color: #FFFFFF;
      font-size: 14px;
      display: inline-block;
      padding: 3px 25px;
      background: #0f649b;
      cursor: pointer;
    }
  }
}
.proContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.dialog_style {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  h3 {
    color: #6699CC;
  }
}
</style>
