import Vue from 'vue'
import Router from 'vue-router'
import VueCookies from 'vue-cookies'

//生成路由规则
const files = require.context('@/views', true, /\.vue$/)
let pages = {};
files.keys().forEach(key => {
  pages[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default;
})
//生成路由规则
import Header from '@/components/header'
import Footer from '@/components/footer'
let generator = [
  {path: '/',name: 'header',component: Header,redirect: '/chinese/home/home'},
  {path: '/',name: 'footer',component: Footer},
];
Object.keys(pages).forEach(item => {
  generator.push({
    path: `/${item.replace(/-/g, '/')}`,
    name: `/${item.replace(/-/g, '/')}`,
    component: pages[item]
  })
});
export default new Router({
	routes: generator
})
Vue.use(Router)
Vue.use(VueCookies);
