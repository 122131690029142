<template>
  <div class="w-p-b-40">
    <div class="w-flex w-row-center">
      <div class="content w-w-full">
        <div class="max-width">
          <div class="w-m-t-30">
            <b-container style="margin-top: 50px">
              <b-row class="w-flex w-row-center w-m-t-30">
                <b-col v-if="newsDetail.content">
                  <div class="w-txt-center">
                    <h2 class="txt-333 txt-s28 txt-bold">{{newsDetail.title}}</h2>
                    <p class="w-m-t-12 txt-999 w-m-b-30">来源：{{newsDetail.keywords}}  发布时间：{{newsDetail.created_at}}</p>

                  </div>
                  <div class="w-m-t-30 txt-666 newsDetail" v-html="newsDetail.content"></div>
                </b-col>
                <b-col v-else>
                  <div class="w-txt-center">
                    <h2 class="txt-333 txt-s28 txt-bold">暂无数据</h2>
                  </div>
                </b-col>
              </b-row>

            </b-container>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import http from '@/http/http'
import { timestampToTime } from '@/utils'
export default {
  data () {
    return {
      newsDetail: {},
      NewsId: '',
      initStatus: false
    }
  },
  methods: {
    // 获取列表
    getNewsList () {
      http.request('News.Detail', {
        NewsId: this.NewsId
      }, (res) => {
        if (res.flag == 200) {
          this.newsDetail = res.data.info
          this.bannerList = res.data.banners
          console.log(res.data.banners)
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    back () {
      if (window.history.length <= 1) {
        this.$router.push({path: '/'})
        return false
      } else {
        this.$router.go(-1)
      }
    }
  },
  activated () {
    this.NewsId = this.$route.query.id || 179
    this.getNewsList()
    this.initStatus = true
  },
  mounted () {
    this.NewsId = this.$route.query.id || 179
    if (this.initStatus) this.getNewsList()
  }
}
</script>
<style scoped lang="scss">
.newsDetail {
  line-height: 40px;
  ::v-deep img {
    width: 100% !important;
  }
}
.newsDetail img {
  width: 940px !important;
}
.rowIntroduce {
  background: #FFFFFF;
  border: 1px solid #C8C8C8;
  overflow: hidden;
  color: #B61A20;
  .more {

    span {
      cursor: pointer;
      i {
        display: inline-block;
        width: 15px;
        height: 15px;
        line-height: 12px;
        text-align: center;
        border: 1px solid #B61A20;
        border-radius: 100%;
      }
    }
  }
}
</style>
